<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-lg cursor-pointer w-72 mt-4 md:w-80 lg:w-96 bg-backfill h-24
      calculator-component
    `"
  >
    <div class="flex flex-col justify-start w-3/4 px-2 py-2 ">
        <p class="font-bold text-left text-primary">{{name}}</p>
        <p class="text-sm text-left text-content">{{description}}</p>
    </div>

        <img
        :src="image"
        alt="Project Image"
        class="object-cover w-1/4 h-full resize-none rounded-r-lg"
    />

  </div>
</template>

<script>

export default {
  name: 'CalculatorComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>
