<template>
    <interior-page-content-container
      nav-selection="calculators"
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="false"
      :notificationMessage.sync="notificationMessage"
      :notificationType="notificationType"
      :notificationTime="notificationTime"
    >

      <template #title>
         Estimators
      </template>

      <template #content>
        <div class="flex flex-col items-center w-full py-2">

          <calculator-component
            v-for="calculator in calculators" :key="calculator.name"
            @click="$router.push({name: calculator.viewName})"
            :name="calculator.name"
            :description="calculator.description"
            :image="calculator.image"
          />

        </div>
      </template>

    </interior-page-content-container>
</template>

<script>
import { USER_WALKTHROUGH_STATE } from '@/store/getters';
import { USER_WALKTHROUGH_DISPATCH } from '@/store/actions';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import CalculatorComponent from '@/components/calculators/CalculatorComponent.vue';

export default {
  name: 'Calculators',
  components: {
    InteriorPageContentContainer,
    CalculatorComponent,
  },
  data() {
    return {
      calculators: [
        {
          name: 'Downsizing',
          viewName: 'CalculatorDownsizing',
          description: 'Downsizing cost estimator',
          image: '/img/calculator-images/downsizing.jpeg',
        },
        {
          name: 'Home Modification',
          viewName: 'CalculatorHomeSafetyUpdates',
          description: 'Home safety/modification cost estimator',
          image: '/img/calculator-images/safety.jpeg',
        },
        {
          name: 'Monthly cost',
          viewName: 'CalculatorMonthlyCosts',
          description: 'Monthly cost change estimator',
          image: '/img/calculator-images/monthly-costs.jpeg',
        },
      ],
      notificationMessage: '',
      notificationType: 'success',
      notificationTime: 5000,
    };
  },
  computed: {
    walkthroughStage() {
      return this.$store.getters[USER_WALKTHROUGH_STATE];
    },
  },
  watch: {
    walkthroughStage() {
      this.onWalkthrough();
    },
    notificationMessage(newVal) {
      if (newVal.length === 0 && this.$store.getters[USER_WALKTHROUGH_STATE] === 26) {
        this.$router.push({ name: 'Dashboard' });
      }
    },
  },
  mounted() {
    this.onWalkthrough();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
      if (to.name === 'Dashboard') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  methods: {
    onWalkthrough() {
      if (this.$store.getters[USER_WALKTHROUGH_STATE] === 26) {
        this.notificationTime = 5000;
        this.notificationType = 'success';
        this.notificationMessage = 'This is where you can access your estimators!';
      }
    },
    backNav() {
      this.$router.push('Dashboard');
    },
  },
};
</script>

<style>

</style>
